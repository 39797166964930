import React, {useEffect, useState, useCallback, useRef} from "react";
// import { useNavigate } from "react-router-dom";
import Grid from "../../../../layout/UI/dataGrid";
import {  GridColDef } from '@mui/x-data-grid';
import './ProjectList.style.scss';
import moment from "moment";
import { Checkbox, InputAdornment, TextField } from '@mui/material';
import { showToast } from "../../../../layout";
import DropdownMulti from "../CommonModule/dropdownMulti";
import { getProjectListConfig, getprojectListFilters, getTooltips } from "../../../../store/projectList/projectListSlice";
import { capitalize, isActionAllowed } from "../../../../utils/helper";
import { PROJECT_LISTVIEW_CONFIGS } from "../Configuration/config";
import { InputTextField } from "../../../../layout/UI/InputTextField";
import { useDispatch } from "react-redux";
import { HUBBLE_URL } from "../../../../store/CONSTANTS";

const ProjectList = () => {
    let dropDownRef : any = useRef();
    let inputRef : any = useRef(null);
    const dispatch: (...args: unknown[]) => Promise<any> = useDispatch<any>();

    const [sorting, setSorting] = useState({sortOrder:'desc',sortBy:'id'});
    const [Data, setData] = useState([])
    const [filterOptions, setFilterOptions] = useState({
        type: [],
        status: [],
        partner: []
    
    }); // [type, status, partner_id]
    const [initialFilterOptions, setInitialFilterOptions] = useState({
        type: [],
        status: [],
        partner: []
    
    }); // [type, status, partner_id]
    const [rowCount, setRowCount] = useState(0);
    const [filterCount, setFilterCount] = useState({
        totalCount: 0,
        selectedCount: 0
    });
    const [search, setSearch] = useState({
        searchTerm: "",
        filters: {
            'type': [],
            'status': {},
            'partner_id': []
        }
    })
    const [pageNumber,setPageNumber]=useState(1);
    const [pageSize,setPageSize]=useState(25);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const checkStatus = (pId: any, sId: any) => {
        if(search.filters.status.hasOwnProperty(pId)){
             if(search.filters.status[pId].includes(sId)){
                 return true;
             }
        }
        return false;
    }


    const getProjectListData = useCallback((page: any, limit: any, search: any) => {
        getProjectListConfig(page, limit, search).then(response => {  // Here the body params is correct , default call
            setData(response.data.data.data);
            setRowCount(response.data.data.total);
        }).catch((err:any)=> showToast('ERROR',err.response.data.message));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {return () => {}},[]);

    useEffect(()=>{
        let handler = (e:any) => {
            if(!inputRef.current.contains(e.target) && !dropDownRef.current.contains(e.target)){
                setIsFilterOpen(false);
            }
        }
        document.addEventListener("mousedown",handler);

        return () => {
            document.removeEventListener('mousedown',handler);
        }
    });
    useEffect(() => {
        const bodyParams = {
            searchTerm: '',
            filters: {
                'type': [],
                'status': {},
                'partner_id': []
            },
            sort: {[sorting.sortBy]:sorting.sortOrder}
        }
        getProjectListData(1, 25, bodyParams);
        filteredData();
        dispatch(getTooltips());// eslint-disable-next-line
    }, []);



    const getDate = (date: any, type: any) => {
        if (date === null) return;
        const newDate = new Date(date);
        return type === 'date' ? moment(newDate).tz('America/Los_Angeles').format('MM/DD/yyyy') : moment.utc(newDate, 'hh:mm A').tz('America/Los_Angeles').format('MM/DD/yyyy - hh:mm A');
    }
    const handlePopoverOpen = (event: any, data: any) => {
        return ;
    };

    const handlePopoverClose = (e: any) => {
        return;
    };

    const projectFilesList = (data: any) => {
            if(isActionAllowed(['hubble-view-project-detail-permission']))
              window.open( `${HUBBLE_URL}dashboard/projects/project-detail/${data.id}`,'_blank')
              //  navigate(`/dashboard/projects/project-detail/${data.id}`);
            else showToast('INFO','You are not authorized to view the Project');   
    }
    const getData = (page: any, limit: any, search: any, filterItems: any) => {
        const bodyParams = {
            searchTerm: search,
            filters: {
                'type': filterItems.type ? filterItems.type : [],
                'partner_id': filterItems.partner_id ? filterItems.partner_id : [],
                'status': filterItems.status ? filterItems.status : [],
            },
            sort:{[sorting.sortBy]:sorting.sortOrder}
        }
        getProjectListConfig(page, limit, bodyParams).then(response => {
            setData(response.data.data.data);
            setRowCount(response.data.data.total);
        })
    }

    const filteredData = () => {
        getprojectListFilters().then(response => {

            let count : any = 0;
            let pipelineStatusCount = 0;
            response.data.data.pipeline_status.forEach((pipeline: { status: {}; }) => {
                pipelineStatusCount += Object.keys(pipeline.status).length;
            });
            count += pipelineStatusCount+Object.keys(response.data.data.type).length+Object.keys(response.data.data.channel_partners).length;
            setFilterCount({...filterCount, totalCount: count});
            setFilterOptions({
                type: response.data.data.type,
                status: response.data.data.pipeline_status,
                partner: response.data.data.channel_partners
            });
            setInitialFilterOptions({
                type: response.data.data.type,
                status: response.data.data.pipeline_status,
                partner: response.data.data.channel_partners
            })
        }).catch((error) => {
            showToast('error', error.response.data.message)
        })
    }

    const handleSelection = (value:any, obj:any, key:any)=>{
        let initialFilters : any = JSON.parse(JSON.stringify(search.filters));
        if(key === 'type' || key === 'partner_id'){
            let keys : any = [];
            for(let id in obj){
                if(value.includes(obj[id])){
                    keys.push(id);
                }
            }
            initialFilters[key] = keys;
        }
        setSearch({...search, filters: initialFilters})
    }

    const handleStatus = (pipeline_id, value, obj) => {
        const initialFilters = { ...search.filters }; 
        const status = initialFilters.status;
    
        if (pipeline_id !== value) {
            const statusArray = status[pipeline_id] || [];
            const indexElement = statusArray.indexOf(parseInt(value));
            if (indexElement > -1) {
                statusArray.splice(indexElement, 1);
                if (statusArray.length === 0) {
                    delete status[pipeline_id];
                }
            } else {
                statusArray.push(parseInt(value));
                status[pipeline_id] = statusArray;
            }
        } else {
            if (status[pipeline_id]?.length === Object.keys(obj.status).length) {
                delete status[pipeline_id];
            } else {
                const existingKeys = status[pipeline_id] || [];
                const newKeys = Object.keys(obj.status).filter(key => !existingKeys.includes(parseInt(key)));
                status[pipeline_id] = [...(existingKeys || []), ...newKeys.map(Number)];
            }
        }
    
        setSearch({ ...search, filters: initialFilters });
    }
    

    const filtereFileData = () => {
        setPageNumber(1)
        const bodyParams = {...search, sort: {[sorting.sortBy]:sorting.sortOrder}}
        getProjectListConfig(1, pageSize, bodyParams).then(response => {
            setData(response.data.data.data);
            setRowCount(response.data.data.total);
        })
    }

    const sortProjectsList = (sortOrder:any, sortBy:any) => {
        setPageNumber(1);
        setSorting({sortOrder:sortOrder,sortBy:sortBy});
        const bodyParams = {
            searchTerm: search.searchTerm,
            filters: {
                'type': search.filters.type ? search.filters.type : [],
                'status': search.filters.status ? search.filters.status : {},
                'partner_id': search.filters.partner_id ? search.filters.partner_id : []
            },
            sort: {[sortBy]:sortOrder}
        }
        getProjectListData(1, pageSize, bodyParams);
      }

      const formatDate = (dateString) => {
        const date = new Date(dateString);
      
        // Extract month, day, and year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
      
        // Return the formatted date
        return `${month}/${day}/${year}`;
      };

    const columns: GridColDef[]  = React.useMemo(
        () => [
            {
                field: "id",className: "first-table",
                type: "string",
                minWidth: 100,
                sortable:false,
                flex: 1, headerName: "Project ID",
                headerAlign:"left", cellClassName: "left-align",
                renderCell: (params) =>  <p style={{ cursor: "pointer",     color: '#2266dd',
                  textDecoration: 'underline' }}>{params.row.id}</p>,
            },
            {
                field: "firstname", className: "first-table",
                type: "string",
                minWidth: 220,
                sortable:false,
                flex: 1,
                renderHeader: () => (
                    <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                      <strong>Customer</strong>
                      <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                        {/* //icon-unfold_more */}
                        {sorting.sortBy !== 'firstname' ? (
                          <span onClick={()=>sortProjectsList('desc','firstname')}>
                          <i className="icon-unfold_more electrum-font"></i>
                        </span>
                        ):
                        sorting.sortOrder === 'asc' ? (
                          <span onClick={()=>sortProjectsList('desc','firstname')}>
                          <i className="icon-arrow_upward electrum-font"></i>
                        </span>
                        ): (
                          <span onClick={()=>sortProjectsList('asc','firstname')}>
                          <i className="icon-arrow_downward electrum-font"></i>
                        </span>
                        )}
                      </div>
                    </div>
                  ),
                headerAlign:"left", cellClassName: "left-align",
                renderCell: (params) =><p>{(params.row.firstname !== null || params.row.lastname !== null) ?`${capitalize(params.row.firstname)}  ${capitalize(params.row.lastname)}` : '-'}</p>,
            },
            {
                field: "project_status", className: "first-table",
                type: "string",
                minWidth: 150,
                sortable:false,
                headerAlign:"left", cellClassName: "left-align",
                flex: 1, headerName: "Status",
                renderHeader: () => (
                    <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                      <strong>Status</strong>
                      <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                        {/* //icon-unfold_more */}
                        {sorting.sortBy !== 'project_status' ? (
                          <span onClick={()=>sortProjectsList('desc','project_status')}>
                          <i className="icon-unfold_more electrum-font"></i>
                        </span>
                        ):
                        sorting.sortOrder === 'asc' ? (
                          <span onClick={()=>sortProjectsList('desc','project_status')}>
                          <i className="icon-arrow_upward electrum-font"></i>
                        </span>
                        ): (
                          <span onClick={()=>sortProjectsList('asc','project_status')}>
                          <i className="icon-arrow_downward electrum-font"></i>
                        </span>
                        )}
                      </div>
                    </div>
                  ),
                renderCell:(params) => <p>{params.row.project_status ? capitalize(params.row.project_status) : '-'}</p>,
            },
            {
                field: "project_type", className: "first-table",
                type: "string",
                minWidth: 100,
                sortable:false,
                headerAlign:"left", cellClassName: "left-align",
                flex: 1,
                renderHeader: () => (
                    <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                      <strong>Project Type</strong>
                      <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                        {/* //icon-unfold_more */}
                        {sorting.sortBy !== 'project_type' ? (
                          <span onClick={()=>sortProjectsList('desc','project_type')}>
                          <i className="icon-unfold_more electrum-font"></i>
                        </span>
                        ):
                        sorting.sortOrder === 'asc' ? (
                          <span onClick={()=>sortProjectsList('desc','project_type')}>
                          <i className="icon-arrow_upward electrum-font"></i>
                        </span>
                        ): (
                          <span onClick={()=>sortProjectsList('asc','project_type')}>
                          <i className="icon-arrow_downward electrum-font"></i>
                        </span>
                        )}
                      </div>
                    </div>
                ),
                renderCell:(params) => <p>{PROJECT_LISTVIEW_CONFIGS.PRODUCT_NAME_MAPPING[params.row.project_type] ? PROJECT_LISTVIEW_CONFIGS.PRODUCT_NAME_MAPPING[params.row.project_type] : '-'}</p>,
            },
            {
                field: "partner_name", className: "first-table",
                type: "string",
                minWidth: 150,
                sortable:false,
                headerAlign:"left", cellClassName: "left-align",
                flex: 1,
                renderHeader: () => (
                    <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                      <strong>Partner Type</strong>
                      <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                        {/* //icon-unfold_more */}
                        {sorting.sortBy !== 'partner_name' ? (
                          <span onClick={()=>sortProjectsList('desc','partner_name')}>
                          <i className="icon-unfold_more electrum-font"></i>
                        </span>
                        ):
                        sorting.sortOrder === 'asc' ? (
                          <span onClick={()=>sortProjectsList('desc','partner_name')}>
                          <i className="icon-arrow_upward electrum-font"></i>
                        </span>
                        ): (
                          <span onClick={()=>sortProjectsList('asc','partner_name')}>
                          <i className="icon-arrow_downward electrum-font"></i>
                        </span>
                        )}
                      </div>
                    </div>
                ),
                renderCell: (params) => <p>{params.row.partner_name}</p>,
            },
            {
                field: "address", className: "first-table",
                type: "string",
                minWidth: 220,
                sortable:false,
                headerAlign:"left", cellClassName: "left-align",
                flex: 1,
                renderHeader: () => (
                    <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                      <strong>Address</strong>
                      <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                        {/* //icon-unfold_more */}
                        {sorting.sortBy !== 'address' ? (
                          <span onClick={()=>sortProjectsList('desc','address')}>
                          <i className="icon-unfold_more electrum-font"></i>
                        </span>
                        ):
                        sorting.sortOrder === 'asc' ? (
                          <span onClick={()=>sortProjectsList('desc','address')}>
                          <i className="icon-arrow_upward electrum-font"></i>
                        </span>
                        ): (
                          <span onClick={()=>sortProjectsList('asc','address')}>
                          <i className="icon-arrow_downward electrum-font"></i>
                        </span>
                        )}
                      </div>
                    </div>
                ),
                renderCell: (params) => (
                    <div className="" onMouseEnter={(event) => handlePopoverOpen(event, params.row.address)}
                        onMouseLeave={(event) => handlePopoverClose(event)}>
                        {
                            <span>{params.row.address.split(',').slice(0, params.row.address.split(',').length - 1).join(',')}</span>
                        }
                    </div>
                )
            },
            {
                field: "created", className: "first-table",
                type: "string",
                minWidth: 170,
                sortable:false,
                headerAlign:"left", cellClassName: "left-align",
                flex: 1,
                renderHeader: () => (
                    <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                      <strong>Created At</strong>
                      <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                    fontWeight: '200',
                    fontSize: 'small'}}>
                        {/* //icon-unfold_more */}
                        {sorting.sortBy !== 'created' ? (
                          <span onClick={()=>sortProjectsList('desc','created')}>
                          <i className="icon-unfold_more electrum-font"></i>
                        </span>
                        ):
                        sorting.sortOrder === 'asc' ? (
                          <span onClick={()=>sortProjectsList('desc','created')}>
                          <i className="icon-arrow_upward electrum-font"></i>
                        </span>
                        ): (
                          <span onClick={()=>sortProjectsList('asc','created')}>
                          <i className="icon-arrow_downward electrum-font"></i>
                        </span>
                        )}
                      </div>
                    </div>
                ),
                renderCell: (params) => <p>{params.row.created ? getDate(params.row.created, 'date'):'NA'}</p>,
            },
            {
                field: "date_signed", className: "first-table",
                type: "string",
                minWidth: 120,
                sortable:false,
                headerAlign:"left", cellClassName: "left-align",
                flex: 1,
                renderHeader: () => (
                  <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                  fontWeight: '200',
                  fontSize: 'small'}}>
                    <strong>Date Signed</strong>
                    <div style={{display:"flex",gap:"4px",fontFamily: 'ActualR',
                  fontWeight: '200',
                  fontSize: 'small'}}>
                      {/* //icon-unfold_more */}
                      {sorting.sortBy !== 'date_signed' ? (
                        <span onClick={()=>sortProjectsList('desc','date_signed')}>
                        <i className="icon-unfold_more electrum-font"></i>
                      </span>
                      ):
                      sorting.sortOrder === 'asc' ? (
                        <span onClick={()=>sortProjectsList('desc','date_signed')}>
                        <i className="icon-arrow_upward electrum-font"></i>
                      </span>
                      ): (
                        <span onClick={()=>sortProjectsList('asc','date_signed')}>
                        <i className="icon-arrow_downward electrum-font"></i>
                      </span>
                      )}
                    </div>
                  </div>
              ),
                renderCell: (params) => <p>{params.row.date_signed ? formatDate(params.row.date_signed):'NA'}</p>,
            },
            {
                field: "project_status_id",
                type: "actions",
                headerName: "Action",
                width: 140,
                hide: (!isActionAllowed(['can-view-project-files']) || !isActionAllowed(['hubble-view-project-detail-permission'])),
                renderCell: (params) => (// eslint-disable-next-line
                    <div> 
                        <a href="null" className="files_link" onClick={() => window.open( `${HUBBLE_URL}dashboard/projects/project-files/${params.row.id}`,'_blank')}>Files</a>
                    </div>
                )
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sortProjectsList]
    );

    const handleRowsPerPageChange = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPageNumber(1);
        getData(1, newPageSize, search.searchTerm, search.filters);
    }

    const finalValue = () => {
        const status = { ...search.filters.status }; 
        let result : any;
    
        const statusKeys = Object.keys(status);
        const singleStatusKey = statusKeys.length === 1 && status[statusKeys[0]].length === 1;
    
        if (singleStatusKey) {
            const pipelineId = statusKeys[0];
            const statusKey = status[pipelineId][0].toString();
    
            const matchingItem = filterOptions.status.find(item => item.pipeline_id.toString() === pipelineId);
    
            if (matchingItem && matchingItem.status[statusKey]) {
                result = matchingItem.status[statusKey];
            } else {
                result = 'Multiple Values';
            }
        } else if (statusKeys.length === 0) {
            result = '';
        } else {
            result = 'Multiple Values';
        }
    
        return result;
    }
    
    const filterOptionsList = (e:any) => {// eslint-disable-next-line
        let filteredArray = initialFilterOptions.status.map(item => {// eslint-disable-next-line
            let status = Object.entries(item.status).filter(([key, value]:any) => value.toLowerCase().includes((e.target.value).toLowerCase()));
            if (status.length > 0) {
                return {
                    pipeline_id: item.pipeline_id,
                    pipeline_name: item.pipeline_name,
                    status: Object.fromEntries(status)
                };
            }
        }).filter(Boolean);
        if((e.target.value).length>0){
            setFilterOptions({...filterOptions,status:filteredArray})
        }else{
            setFilterOptions({...filterOptions,status:initialFilterOptions.status})
        }
    }
    const statusUpdateHandler =() => {
      setIsFilterOpen(!Boolean(isFilterOpen))
    }

    return (
      <>
        <div className="projectList-container">
          <div className="outer-box">
            <div className="top-header">Projects</div>
            <div className="input">
              <div className="d-flex projectList-filter-section">
                <div className="filter-box">
                  <TextField
                    className="searchText"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height:'48px',
                        "& fieldset": {
                          borderColor: "rgba(102, 102, 102, 0.5)", // Default
                        },
                        "&:hover fieldset": {
                          borderColor: "rgba(102, 102, 102, 0.5)", // Hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(102, 102, 102, 0.5)", // Focused
                        },
                      },
                    }}
                    size="small"
                    placeholder="Search Project ID, Customer Name, or Address"
                    value={search.searchTerm}
                    onChange={(event) => {
                      setSearch({ ...search, searchTerm: event.target.value });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="icon-h-search icon"></i>
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        filtereFileData();
                      }
                    }}
                  />
                </div>
                <DropdownMulti
                  className="multi_select_dropdown"
                  partner={Object.keys(filterOptions.type).map(
                    (item) => filterOptions.type[item]
                  )}
                  data={
                    Object.keys(filterOptions.type).map(
                      (item) => filterOptions.type[item]
                    ) || []
                  }
                  label="Project Type"
                  handleSelection={(e: any) =>
                    handleSelection(e, filterOptions.type, "type")
                  }
                ></DropdownMulti>
                <DropdownMulti
                  className="multi_select_dropdown"
                  partner={Object.keys(filterOptions.partner).map(
                    (item) => filterOptions.partner[item]
                  )}
                  data={
                    Object.keys(filterOptions.partner).map(
                      (item) => filterOptions.partner[item]
                    ) || []
                  }
                  label="Partner Type"
                  handleSelection={(e: any) =>
                    handleSelection(e, filterOptions.partner, "partner_id")
                  }
                ></DropdownMulti>
                <div className="status-box">
                  <div ref= {inputRef}>
                    <InputTextField
                      className="anualField_1"
                      name="status"
                      size="small"
                      label="Status"
                      value={finalValue()}
                      sx={{
                        input: {
                          cursor: "pointer",
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <i style={{position:'absolute', right:'0', pointerEvents:'none' }}  className="icon-h-down-arrow icon_down_array_status"></i>
                          </InputAdornment>
                        ),
                        readOnly:true
                      }}
                      onClick={statusUpdateHandler}
                    ></InputTextField>
                  </div>
                  <div
                    className={`status_options_list_main ${
                      isFilterOpen ? "active" : "inactive"
                    }`}
                    ref={dropDownRef}
                  >
                    <div>
                      <input
                        className="search_box"
                        type="text"
                        placeholder="Search"
                        onChange={filterOptionsList}
                      />
                    </div>
                    {filterOptions.status &&
                      filterOptions.status.map((item, index) => {
                        return (
                          <div className="status_checkbox_main">
                            <div className="filter_prop_text" key={index}>
                              <div style={{ display: "flex" }}>
                                <Checkbox
                                  checked={
                                    search.filters.status.hasOwnProperty(
                                      item.pipeline_id
                                    ) &&
                                    search.filters.status[item.pipeline_id]
                                      .length ===
                                      Object.keys(item.status).length
                                  }
                                  onClick={(e) => {
                                    handleStatus(
                                      item.pipeline_id,
                                      item.pipeline_id,
                                      item
                                    );
                                  }}
                                />
                                <label
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontFamily: "ActualM",
                                    fontSize: "13px",
                                  }}
                                  htmlFor={item.pipeline_name}
                                >
                                  {item.pipeline_name}
                                </label>
                              </div>
                            </div>
                            <div className="select_project_type">
                              {item.status &&
                                Object.keys(item.status).map(
                                  (ele, statusIndex) => {
                                    return (
                                      <div
                                        key={statusIndex}
                                        className="filter_checkbox"
                                      >
                                        <Checkbox
                                          size="small"
                                          checked={checkStatus(
                                            item.pipeline_id,
                                            parseInt(ele)
                                          )}
                                          onClick={(e) =>
                                            handleStatus(
                                              item.pipeline_id,
                                              ele,
                                              item
                                            )
                                          }
                                        />
                                        <label
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          htmlFor={ele}
                                        >
                                          {item.status[ele]}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="filter-search-button">
                  <i
                    className="icon-h-search btn-layout"
                    onClick={() => filtereFileData()}
                  ></i>
                </div>
                {/* </div> */}

                {/* <div className='filter-button'>
                                <i className="icon-filter_list filter-btn-layout" onClick={() => {
                                setIsFilterOpen(!isFilterOpen);
                                }}></i>
                            </div> 
                            {isFilterOpen &&
                                <div className="filter_container">
                                        <div className="div_main1">
                                            <div className="filter_prop">
                                                <div className="filter_prop_text">Filter Properties</div>
                                                <span className="clear_text" onClick={()=>{
                                                    setFilterCount({...filterCount, selectedCount: 0});
                                                    setSearch({
                                                        ...search,
                                                        filters: {
                                                            'type': [],
                                                            'status': {},
                                                            'partner_id': []
                                                        }
                                                    })
                                                }
                                                }>Clear All</span>
                                            </div>
                                            <div className="select_txt">Select properties that you want to see inside the table</div>
                                            <hr style={{width:'100%'}} />
                                        </div>
                                        <div className="title">Status</div>
                                        <div className="div_main3">
                                            <div className="project_type_main">
                                                {filterOptions.status && filterOptions.status.map((item, index) => {
                                                    return( 
                                                        <div className="status_checkbox_main">
                                                            <div className="filter_prop_text" key={index}>{item.pipeline_name}</div>
                                                            <div className="select_project_type">
                                                                {item.status && Object.keys(item.status).map((ele, statusIndex) => {
                                                                return <div key={statusIndex} className="filter_checkbox">
                                                                    <Checkbox
                                                                        checked= {checkStatus(item.pipeline_id, parseInt(ele))}

                                                                        onClick={(e) => handleSelection(item.pipeline_id, ele,'status')}
                                                                    />
                                                                    <label style={{ display:'flex', justifyContent:'center',alignItems:'center'}} htmlFor={ele}>{item.status[ele]}</label>
                                                                </div>
                                                                })}
                                                            </div>
                                                            <hr />
                                                        </div>  
                                                        
                                                )})}
                                            </div>  
                                        </div>
                                        
                                </div>}  */}
              </div>
            </div>

            <div style={{ margin: " 0px 16px" }}>
              {Data.length === 0 ? (
                <div className="no-result">
                  <p>No Results Are Found For Your Search Criteria.</p>
                </div>
              ) : (
                <Grid
                  columns={columns}
                  rows={Data}
                  autoHeight={true}
                  pageSize={pageSize}
                  rowsPerPageSelection={[25, 50, 75, 100]}
                  paginationMode="server"
                  rowCount={rowCount}
                  toolbar={false}
                  onRowClick={(row: any) =>{
                    if(row.row.project_type === 'HPWH'){
                      showToast('INFO','Coming soon..')
                    }else{
                      projectFilesList(row.row);
                    }
                  }}
                  onPageChange={(newPage: any) => {
                    setPageNumber(newPage + 1);
                    getData(
                      newPage + 1,
                      pageSize,
                      search.searchTerm,
                      search.filters
                    ); // remove 4th param
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 1, pageSize: pageSize },
                    },
                  }}
                  pagenumber={pageNumber}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
}


export default ProjectList;